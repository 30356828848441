import { useState, useEffect } from 'react';

const defaultMobile = 960;

function useMobileView({
  mobileBreakpoint = defaultMobile,
} = {}) {
  const [isMobileView, setMobileView] = useState(window.innerWidth < mobileBreakpoint);

  useEffect(() => {
    const handleResize = () => setMobileView(window.innerWidth < mobileBreakpoint);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobileView;
}

export default useMobileView;
