import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import { createRoot } from 'react-dom/client';
import { times } from 'lodash';

import Slide from './Slide';
import Carousel3D from '../../../../components/Carousel3D';
import useMobileView from '../../сompetitive-intelligence-reports/shared/hooks/useMobileView';

const config = () => ({
  arrows: false,
  autoplaySpeed: 0,
  autoplay: true,
  dots: false,
  draggable: false,
  infinite: true,
  pauseOnHover: false,
  swipe: false,
  variableWidth: true,
  centerMode: false,
  centerPadding: 0,
  speed: 4000,
  cssEase: 'linear',
  slidesToShow: 8,
  slidesToScroll: 1,
  rtl: false,
  responsive: [{
    breakpoint: 1200,
    settings: {
      slidesToShow: 5,
    },
  }],
});

const LogosLoopingScroll = ({ data }) => {
  const [slides, setSlides] = useState([]);
  const isMobileView = useMobileView();

  if (isMobileView) return null;

  useEffect(() => {
    setTimeout(() => setSlides(data), 300);
  }, []);

  return (
    <Carousel3D
      focusOnLoad
      className="logos-hms-looping"
      config={config(isMobileView)}
      slides={slides.map(slide => (
        <Slide
          key={slide.id}
          data={slide}
        />
      ))}
    />
  );
};
LogosLoopingScroll.propTypes = {
  data: T.array,
};

export default LogosLoopingScroll;

document.addEventListener('DOMContentLoaded', () => {
  const rootNode = document.getElementById('logos-looping-scroll-root');

  if (rootNode) {
    const slides = [];
    const minCount = 12;

    rootNode.querySelectorAll('.logo-slide').forEach((item) => {
      slides.push(JSON.parse(item.dataset.logo));
    });

    if (!slides.length) return;

    const prepareData = () => {
      if (slides.length >= minCount) return slides;

      const repeatCount = Math.ceil((minCount - slides.length) / slides.length);

      return times(repeatCount)
        .reduce(memo => [...memo, ...slides], slides)
        .map((slide, index) => ({ ...slide, id: index }));
    };
    const root = createRoot(rootNode);
    root.render(<LogosLoopingScroll data={prepareData(slides)} />);
  }
});

// Slide fields
// id
// logo_src
