import React from 'react';
import T from 'prop-types';

const Slide = ({
  data,
}) => (
  <div className="logo-slot">
    <img
      src={data.logo_src}
      alt="logo"
      className="logo-slot-image"
    />
  </div>
);

Slide.propTypes = {
  data: T.object.isRequired,
};

export default Slide;
